import React from "react"

import SEO from "../../components/seo"

import instance  from "../../components/api/httpclient"

import moment from "moment";
import { navigate, useIntl, Link } from "gatsby-plugin-intl"
import GenericVehicle from "../../assets/images/vehicle/generic.png";
import ReactImageFallback from "react-image-fallback";

const AddCarAdded = (navigateTo) => {

  const car = typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('car')): {car :{}, model:{}} ;
  const intl = useIntl();

  function validCar(event, id) {
    instance.post('/add-car',
      {carId: id},
      {
        headers: {
          'Authorization': localStorage.getItem('token')
        }
      }).then((response) => {
        navigate(navigateTo.navigateTo);
    });
    event.preventDefault();
  }

  return (
      <div>
        <SEO title={intl.formatMessage({ id: "pages_meta_title.profile.add-car-added" })}/>

        <h1 className="text-center text-uppercase pt-4 mb-4">{intl.formatMessage({ id: "1_2_1_3_onboarding_add_car_added.result_title" })} <span
          className="icon-check green"></span></h1>


        <div className="white-container w-auto mb-4 px-3 py-4 px-sm-4">


          <div className="row">

            <div className="col-md-4 col-xl-3">
              <div className="text-center">
                {/*<img src={car.model.image} alt={car.model.name} className="img-fluid" width="446"/>*/}
                <ReactImageFallback
                  src={car.model.image}
                  fallbackImage={GenericVehicle}
                  alt={car.model.name}
                  className="img-fluid"
                  width="446" /><br/>
                <h2 className="mb-1">{car.model.name}</h2>
                <h3 className="mb-2">{car.car.licencePlate}</h3>
              </div>
            </div>
            <div className="col-md-8 col-xl-9">
              <h1 className="with-border-top with-border-bottom text-uppercase">{intl.formatMessage({ id: "1_2_1_3_onboarding_add_car_added.title" })}</h1>

              <div className="row mb-4">
                <div className="col-xl-6 mb-4">
                  <span className="d-inline-block w-50 grey-border align-top">{intl.formatMessage({ id: "1_2_1_3_onboarding_add_car_added.chassi_number" })}</span><span
                  className="d-inline-block w-50 text align-top text-break"><strong>{car.car.vin}</strong></span><br/>
                  <span className="d-inline-block w-50 grey-border align-middle">{intl.formatMessage({ id: "1_2_1_3_onboarding_add_car_added.engine" })}</span><span
                  className="d-inline-block w-50 text align-middle"><strong>{car.car.engine}</strong></span><br/>
                  <span className="d-inline-block w-50 grey-border align-middle">{intl.formatMessage({ id: "1_2_1_3_onboarding_add_car_added.finish" })}</span><span
                  className="d-inline-block w-50 text align-middle"><strong>{car.car.grade}</strong></span><br/>
                  <span className="d-inline-block w-50 grey-border align-middle">{intl.formatMessage({ id: "1_2_1_3_onboarding_add_car_added.fuel_type" })}</span><span
                  className="d-inline-block w-50 text align-middle"><strong>{intl.formatMessage({ id: "generic.fuel_type."+car.car.fuel })}</strong></span><br/>
                  <span className="d-inline-block w-50 grey-border align-middle">{intl.formatMessage({ id: "1_2_1_3_onboarding_add_car_added.registration_date" })}</span><span
                  className="d-inline-block w-50 text align-middle"><strong>{moment(car.car.firstRegistrationDate, moment.ISO_8601).format('DD/MM/YYYY')}</strong></span>
                </div>
                <div className="col-xl-6 mb-4">
                  <span className="d-inline-block w-50 grey-border align-middle">{intl.formatMessage({ id: "1_2_1_3_onboarding_add_car_added.last_service_date" })}</span><span
                  className="d-inline-block w-50 text align-middle"><strong>{car.car.lastMaintenanceDate ? moment(car.car.lastMaintenanceDate, moment.ISO_8601).format('DD/MM/YYYY') : intl.formatMessage({ id: "generic.mentions.unknown" })}</strong></span><br/>
                  <span className="d-inline-block w-50 grey-border align-middle">{intl.formatMessage({ id: "1_2_1_3_onboarding_add_car_added.mileage" })}</span><span
                  className="d-inline-block w-50 text align-middle"><strong>{car.car.mileage} KM</strong></span>
                </div>
              </div>

              <div>
                <Link to="/profile/add-car-kms" className="button red mx-auto mr-lg-0" onClick={(event => validCar(event, car.car.id))}>{intl.formatMessage({ id: "generic.nav_buttons.next_button" })}</Link>
              </div>
            </div>

          </div>

        </div>
      </div>
  );
};

export default AddCarAdded
