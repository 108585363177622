import React from "react"

import BarStep from "./partials/bar-step"
import CarAdded from "../car/car-added"
import Layout from "../../components/layout";

const AddCarAddedPage = () => {
  return (
      <div>
        <Layout>
          <BarStep selected="step1" />
          <CarAdded navigateTo='/profile/add-car-kms'/>
        </Layout>
      </div>
  );
};

export default AddCarAddedPage
